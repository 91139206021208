import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
// import ContentTypePagination from "../../components/ContentTypePagination"
// import PostMeta from "../../components/PostMeta"
// import FeaturedMedia from "../../components/FeaturedMedia"
import PostPreview from "../../components/PostPreview"
import Post from "../../components/Post"
import PostBioPreview from "../../components/PostBioPreview"
import PostBio from "../../components/PostBio"


const TypePost = ({ pageContext, data, location }) => {
  const { page, categoryPosts } = data
  const {
    title,
    excerpt,
    databaseId,
    language,
    translations,
  } = page
  // console.log(language, translations)

  // state handling click on PostPreview to animate closing current and opening new Post
  const [newPostId, setNewPostId] = useState(databaseId)

  // categories for biography template
  const bioCategories = ["biografie", "biography"]

  /* BIOGRAPHY category */
  if (bioCategories.indexOf(pageContext.categorySlug) > -1) {
    return (
      <Layout
        location={location}
        language={language}
        translations={translations}
        bodyClass={`archive category-${pageContext.categorySlug} post-template-default single single-post postid-${databaseId} single-format-standard wp-embed-responsive singular has-post-thumbnail has-single-pagination footer-top-visible`}
      >
        <Seo title={title} description={excerpt} lang={language.slug} />

        <header className="archive-header header-footer-group page-header">
          <div className="archive-header-inner section-inner medium page-header-inner">
            <h1 className="archive-title color-accent">
              {pageContext.categoryName}
            </h1>
          </div>
        </header>

        {/* All posts with same category as this post */}
        <div className="section-inner posts-preview">
          {categoryPosts.nodes &&
            categoryPosts.nodes.map((cPost, index) => {
              let myPost
              myPost = <PostBioPreview key={cPost.databaseId} post={cPost} />
              return myPost
            })}
        </div>

        {/* This post */}
        <PostBio key={databaseId} post={page} />
      </Layout>
    )
  } else {
    /* ALL OTHER CATEGORIES */

    return (
      <Layout
        location={location}
        language={language}
        translations={translations}
        bodyClass={`archive archive-accordion category-${pageContext.categorySlug} post-template-default single single-post postid-${databaseId} single-format-standard wp-embed-responsive singular has-post-thumbnail has-single-pagination footer-top-visible`}
      >
        <Seo title={title} description={excerpt} lang={language.slug} />

        <header className="archive-header header-footer-group page-header">
          <div className="archive-header-inner section-inner medium page-header-inner">
            <h1 className="archive-title color-accent">
              {pageContext.categoryName}
            </h1>
          </div>
        </header>

        {/* All posts with same category as this post */}
        {categoryPosts.nodes &&
          categoryPosts.nodes.map((cPost, index) => {
            let myPost
            if (cPost.databaseId === databaseId) {
              myPost = (
                <Post
                  key={cPost.databaseId}
                  post={page}
                  isLast={index === categoryPosts.nodes.length - 1}
                  isNewYear={
                    index === 0 ||
                    cPost.year !== categoryPosts.nodes[index - 1].year
                  }
                  newPostId={newPostId}
                />
              )
            } else {
              myPost = (
                <PostPreview
                  key={cPost.databaseId}
                  post={cPost}
                  isLast={index === categoryPosts.nodes.length - 1}
                  isNewYear={
                    index === 0 ||
                    cPost.year !== categoryPosts.nodes[index - 1].year
                  }
                  setNewPostId={setNewPostId}
                  contentType="post"
                />
              )
            }
            return myPost
          })}
      </Layout>
    )
  }
}

export const query = graphql`
  query post($id: String!, $categoryId: Int) {
    page: wpPost(id: { eq: $id }) {
      ...PostContent
    }
    categoryPosts: allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { databaseId: { eq: $categoryId } } }
        }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        ...PostPreviewContent
      }
    }
  }
`

export default TypePost