import React from "react"
import { Link } from "gatsby"
import { navigate } from "gatsby"
// import PostMeta from "./PostMeta"
// import FeaturedMedia from "./FeaturedMedia"

const PostBioPreview = ({
  post,
  isLast,
  isNewYear,
  setNewPostId,
  contentType,
}) => {
  // console.log(sendNewPostId)

  return (
    <>
      
      <article
        className={`post-${post.databaseId} post-preview post type-post status-publish format-standard has-post-thumbnail hentry category-${post.categories.nodes[0].slug}`}
        id={`post-${post.databaseId}`}
        data-date={post.date}
      >
        <header className="entry-header">
          <div className="entry-header-inner section-inner medium">
            <h2 className="entry-title heading-size-1">
              <Link
                to={post.uri}
                className="d-block color-accent-hover"
                activeClassName="active"
                dangerouslySetInnerHTML={{ __html: post.title }}
              />
            </h2>
            {/* <PostMeta date={post.date} year={post.year} /> */}
          </div>
        </header>
      </article>

      {/* {!isLast && (
        <hr
          key={post.postId + "-hr"}
          className="post-separator styled-separator is-style-wide section-inner"
          aria-hidden="true"
        />
      )} */}
    </>
  )
}

export default PostBioPreview
