import React from "react"
import { Link } from "gatsby"
import Slide from "react-reveal/Slide"
// import { Link } from "gatsby"
// import ContentTypePagination from "./ContentTypePagination"
// import PostMeta from "./PostMeta"
// import FeaturedMedia from "./FeaturedMedia"

const PostBio = ({ post, isLast, isNewYear, newPostId }) => {
  /*
  // state handling for react-reveal Slide
  const [show, setShow] = useState(true)
  const handleClick = (e) => {
    setShow(!show)
  }
  */

  return (
    <>

      <article
        className={`post-${post.databaseId} post-active post type-post status-publish format-standard has-post-thumbnail hentry category-${post.categories.nodes[0].slug}`}
        id={`post-${post.databaseId}`}
        data-date={post.date}
      >
        <header className="entry-header header-footer-group has-accent-color">
          <div className="entry-header-inner section-inner medium">
            <h1 className="entry-title">
              <Link
                to={post.categories.nodes[0].uri}
                className="d-block color-primary-hover"
                activeClassName="active"
                dangerouslySetInnerHTML={{ __html: post.title }}
                // onClick={handleClick}
              />
            </h1>
            {/* <PostMeta date={post.date} year={post.year} /> */}
          </div>
        </header>

        {/* <FeaturedMedia image={post.featuredImage} /> */}

        <div className="post-inner thin">
          <div
            className="entry-content"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
        </div>

        {/* <div className="section-inner">
          <ContentTypePagination
            previousPage={post.previousPage}
            nextPage={post.nextPage}
            contentType={"Post"}
          />
        </div> */}
      </article>

      {/* {!isLast && (
        <hr
          key={post.postId + "-hr"}
          className="post-separator styled-separator is-style-wide section-inner"
          aria-hidden="true"
        />
      )} */}
    </>
  )
}

export default PostBio
